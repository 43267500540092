var $jscomp={scope:{},getGlobal:function(a){return"undefined"!=typeof window&&window===a?a:"undefined"!=typeof global&&null!=global?global:a}};$jscomp.global=$jscomp.getGlobal(this);$jscomp.defineProperty="function"==typeof Object.defineProperties?Object.defineProperty:function(a,c,e){if(e.get||e.set)throw new TypeError("ES3 does not support getters and setters.");a!=Array.prototype&&a!=Object.prototype&&(a[c]=e.value)};
$jscomp.polyfill=function(a,c,e,k){if(c){e=$jscomp.global;a=a.split(".");for(k=0;k<a.length-1;k++){var p=a[k];p in e||(e[p]={});e=e[p]}a=a[a.length-1];k=e[a];c=c(k);c!=k&&null!=c&&$jscomp.defineProperty(e,a,{configurable:!0,writable:!0,value:c})}};$jscomp.polyfill("Array.prototype.fill",function(a){return a?a:function(a,e,k){var c=this.length||0;0>e&&(e=Math.max(0,c+e));if(null==k||k>c)k=c;k=Number(k);0>k&&(k=Math.max(0,c+k));for(e=Number(e||0);e<k;e++)this[e]=a;return this}},"es6-impl","es3");
$jscomp.SYMBOL_PREFIX="jscomp_symbol_";$jscomp.initSymbol=function(){$jscomp.initSymbol=function(){};$jscomp.global.Symbol||($jscomp.global.Symbol=$jscomp.Symbol)};$jscomp.symbolCounter_=0;$jscomp.Symbol=function(a){return $jscomp.SYMBOL_PREFIX+(a||"")+$jscomp.symbolCounter_++};
$jscomp.initSymbolIterator=function(){$jscomp.initSymbol();var a=$jscomp.global.Symbol.iterator;a||(a=$jscomp.global.Symbol.iterator=$jscomp.global.Symbol("iterator"));"function"!=typeof Array.prototype[a]&&$jscomp.defineProperty(Array.prototype,a,{configurable:!0,writable:!0,value:function(){return $jscomp.arrayIterator(this)}});$jscomp.initSymbolIterator=function(){}};$jscomp.arrayIterator=function(a){var c=0;return $jscomp.iteratorPrototype(function(){return c<a.length?{done:!1,value:a[c++]}:{done:!0}})};
$jscomp.iteratorPrototype=function(a){$jscomp.initSymbolIterator();a={next:a};a[$jscomp.global.Symbol.iterator]=function(){return this};return a};$jscomp.polyfill("Array.from",function(a){return a?a:function(a,e,k){$jscomp.initSymbolIterator();e=null!=e?e:function(a){return a};var c=[],r=a[Symbol.iterator];if("function"==typeof r)for(a=r.call(a);!(r=a.next()).done;)c.push(e.call(k,r.value));else for(var r=a.length,h=0;h<r;h++)c.push(e.call(k,a[h]));return c}},"es6-impl","es3");
$jscomp.owns=function(a,c){return Object.prototype.hasOwnProperty.call(a,c)};$jscomp.polyfill("Object.assign",function(a){return a?a:function(a,e){for(var c=1;c<arguments.length;c++){var p=arguments[c];if(p)for(var r in p)$jscomp.owns(p,r)&&(a[r]=p[r])}return a}},"es6-impl","es3");$jscomp.findInternal=function(a,c,e){a instanceof String&&(a=String(a));for(var k=a.length,p=0;p<k;p++){var r=a[p];if(c.call(e,r,p,a))return{i:p,v:r}}return{i:-1,v:void 0}};
$jscomp.polyfill("Array.prototype.find",function(a){return a?a:function(a,e){return $jscomp.findInternal(this,a,e).v}},"es6-impl","es3");
!function(a,c){var e={},k={};!function(a,c){function h(){this._endDelay=this._delay=0;this._fill="none";this._iterationStart=0;this._iterations=1;this._duration=0;this._playbackRate=1;this._direction="normal";this._easing="linear";this._easingFunction=w}function e(){return a.isDeprecated("Invalid timing inputs","2016-03-02","TypeError exceptions will be thrown instead.",!0)}function k(b,f,g){var q=new h;return f&&(q.fill="both",q.duration="auto"),"number"!=typeof b||isNaN(b)?void 0!==b&&Object.getOwnPropertyNames(b).forEach(function(f){"auto"==
b[f]||("number"==typeof q[f]||"duration"==f)&&("number"!=typeof b[f]||isNaN(b[f]))||"fill"==f&&-1==d.indexOf(b[f])||"direction"==f&&-1==l.indexOf(b[f])||"playbackRate"==f&&1!==b[f]&&a.isDeprecated("AnimationEffectTiming.playbackRate","2014-11-28","Use Animation.playbackRate instead.")||(q[f]=b[f])}):q.duration=b,q}function t(b,d,a,f){return 0>b||1<b||0>a||1<a?w:function(q){if(0>=q){var g=0;return 0<b?g=d/b:!d&&0<a&&(g=f/a),g*q}if(1<=q)return g=0,1>a?g=(f-1)/(a-1):1==a&&1>b&&(g=(d-1)/(b-1)),1+g*(q-
1);for(var g=0,h=1;g<h;){var l=(g+h)/2,n=3*b*(1-l)*(1-l)*l+3*a*(1-l)*l*l+l*l*l;if(1E-5>Math.abs(q-n))break;n<q?g=l:h=l}return 3*d*(1-l)*(1-l)*l+3*f*(1-l)*l*l+l*l*l}}function g(b,d){return function(a){if(1<=a)return 1;var f=1/b;return a+=d*f,a-a%f}}function n(b){m||(m=document.createElement("div").style);m.animationTimingFunction="";m.animationTimingFunction=b;var d=m.animationTimingFunction;if(""==d&&e())throw new TypeError(b+" is not a valid value for easing");return d}function f(b){if("linear"==
b)return w;var d=F.exec(b);return d?t.apply(this,d.slice(1).map(Number)):(d=G.exec(b))?g(Number(d[1]),{start:q,middle:x,end:y}[d[2]]):(b=p[b])?b:w}function b(b,d,a){if(null==d)return r;var f=a.delay+b+a.endDelay;return d<Math.min(a.delay,f)?C:d>=Math.min(a.delay+b,f)?B:E}var d=["backwards","forwards","both","none"],l=["reverse","alternate","alternate-reverse"],w=function(b){return b};h.prototype={_setMember:function(b,d){this["_"+b]=d;this._effect&&(this._effect._timingInput[b]=d,this._effect._timing=
a.normalizeTimingInput(this._effect._timingInput),this._effect.activeDuration=a.calculateActiveDuration(this._effect._timing),this._effect._animation&&this._effect._animation._rebuildUnderlyingAnimation())},get playbackRate(){return this._playbackRate},set delay(b){this._setMember("delay",b)},get delay(){return this._delay},set endDelay(b){this._setMember("endDelay",b)},get endDelay(){return this._endDelay},set fill(b){this._setMember("fill",b)},get fill(){return this._fill},set iterationStart(b){if((isNaN(b)||
0>b)&&e())throw new TypeError("iterationStart must be a non-negative number, received: "+timing.iterationStart);this._setMember("iterationStart",b)},get iterationStart(){return this._iterationStart},set duration(b){if("auto"!=b&&(isNaN(b)||0>b)&&e())throw new TypeError("duration must be non-negative or auto, received: "+b);this._setMember("duration",b)},get duration(){return this._duration},set direction(b){this._setMember("direction",b)},get direction(){return this._direction},set easing(b){this._easingFunction=
f(n(b));this._setMember("easing",b)},get easing(){return this._easing},set iterations(b){if((isNaN(b)||0>b)&&e())throw new TypeError("iterations must be non-negative, received: "+b);this._setMember("iterations",b)},get iterations(){return this._iterations}};var q=1,x=.5,y=0,p={ease:t(.25,.1,.25,1),"ease-in":t(.42,0,1,1),"ease-out":t(0,0,.58,1),"ease-in-out":t(.42,0,.58,1),"step-start":g(1,q),"step-middle":g(1,x),"step-end":g(1,y)},m=null,F=/cubic-bezier\(\s*(-?\d+\.?\d*|-?\.\d+)\s*,\s*(-?\d+\.?\d*|-?\.\d+)\s*,\s*(-?\d+\.?\d*|-?\.\d+)\s*,\s*(-?\d+\.?\d*|-?\.\d+)\s*\)/,
G=/steps\(\s*(\d+)\s*,\s*(start|middle|end)\s*\)/,r=0,C=1,B=2,E=3;a.cloneTimingInput=function(b){if("number"==typeof b)return b;var d={},a;for(a in b)d[a]=b[a];return d};a.makeTiming=k;a.numericTimingToObject=function(b){return"number"==typeof b&&(b=isNaN(b)?{duration:0}:{duration:b}),b};a.normalizeTimingInput=function(b,d){return b=a.numericTimingToObject(b),k(b,d)};a.calculateActiveDuration=function(b){return Math.abs((0===b.duration||0===b.iterations?0:b.duration*b.iterations)/b.playbackRate)};
a.calculateIterationProgress=function(d,a,f){var l=b(d,a,f);a:{var g=f.fill;switch(l){case C:d="backwards"==g||"both"==g?0:null;break a;case E:d=a-f.delay;break a;case B:d="forwards"==g||"both"==g?d:null;break a;case r:d=null;break a}d=void 0}if(null===d)return null;a=f.duration;g=f.iterationStart;a=(0===a?l!==C&&(g+=f.iterations):g+=d/a,g);g=a===1/0?f.iterationStart%1:a%1;d=(0!==g||l!==B||0===f.iterations||0===d&&0!==f.duration||(g=1),g);g=l===B&&f.iterations===1/0?1/0:1===d?Math.floor(a)-1:Math.floor(a);
a=l=f.direction;"normal"!==l&&"reverse"!==l&&("alternate-reverse"===l&&(g+=1),a="normal",g!==1/0&&0!==g%2&&(a="reverse"));return f._easingFunction("normal"===a?d:1-d)};a.calculatePhase=b;a.normalizeEasing=n;a.parseEasingFunction=f}(e,null);(function(a,c){function h(a){var g=[],f;for(f in a)if(!(f in["easing","offset","composite"])){var b=a[f];Array.isArray(b)||(b=[b]);for(var d,l=b.length,h=0;h<l;h++)d={},"offset"in a?d.offset=a.offset:1==l?d.offset=1:d.offset=h/(l-1),"easing"in a&&(d.easing=a.easing),
"composite"in a&&(d.composite=a.composite),d[f]=b[h],g.push(d)}return g.sort(function(b,d){return b.offset-d.offset}),g}var e={background:"backgroundImage backgroundPosition backgroundSize backgroundRepeat backgroundAttachment backgroundOrigin backgroundClip backgroundColor".split(" "),border:"borderTopColor borderTopStyle borderTopWidth borderRightColor borderRightStyle borderRightWidth borderBottomColor borderBottomStyle borderBottomWidth borderLeftColor borderLeftStyle borderLeftWidth".split(" "),
borderBottom:["borderBottomWidth","borderBottomStyle","borderBottomColor"],borderColor:["borderTopColor","borderRightColor","borderBottomColor","borderLeftColor"],borderLeft:["borderLeftWidth","borderLeftStyle","borderLeftColor"],borderRadius:["borderTopLeftRadius","borderTopRightRadius","borderBottomRightRadius","borderBottomLeftRadius"],borderRight:["borderRightWidth","borderRightStyle","borderRightColor"],borderTop:["borderTopWidth","borderTopStyle","borderTopColor"],borderWidth:["borderTopWidth",
"borderRightWidth","borderBottomWidth","borderLeftWidth"],flex:["flexGrow","flexShrink","flexBasis"],font:"fontFamily fontSize fontStyle fontVariant fontWeight lineHeight".split(" "),margin:["marginTop","marginRight","marginBottom","marginLeft"],outline:["outlineColor","outlineStyle","outlineWidth"],padding:["paddingTop","paddingRight","paddingBottom","paddingLeft"]},k=document.createElementNS("http://www.w3.org/1999/xhtml","div");c={thin:"1px",medium:"3px",thick:"5px"};var t={borderBottomWidth:c,
borderLeftWidth:c,borderRightWidth:c,borderTopWidth:c,fontSize:{"xx-small":"60%","x-small":"75%",small:"89%",medium:"100%",large:"120%","x-large":"150%","xx-large":"200%"},fontWeight:{normal:"400",bold:"700"},outlineWidth:c,textShadow:{none:"0px 0px 0px transparent"},boxShadow:{none:"0px 0px 0px 0px transparent"}};a.convertToArrayForm=h;a.normalizeKeyframes=function(g){function n(){var b=f.length;null==f[b-1].offset&&(f[b-1].offset=1);1<b&&null==f[0].offset&&(f[0].offset=0);for(var d=0,a=f[0].offset,
l=1;l<b;l++){var g=f[l].offset;if(null!=g){for(var h=1;h<l-d;h++)f[d+h].offset=a+(g-a)*h/(l-d);d=l;a=g}}}if(null==g)return[];window.Symbol&&Symbol.iterator&&Array.prototype.from&&g[Symbol.iterator]&&(g=Array.from(g));Array.isArray(g)||(g=h(g));var f=g.map(function(b){var d={},f;for(f in b){var l=b[f];if("offset"==f){if(null!=l){if(l=Number(l),!isFinite(l))throw new TypeError("Keyframe offsets must be numbers.");if(0>l||1<l)throw new TypeError("Keyframe offsets must be between 0 and 1.");}}else if("composite"==
f){if("add"==l||"accumulate"==l)throw{type:DOMException.NOT_SUPPORTED_ERR,name:"NotSupportedError",message:"add compositing is not supported"};if("replace"!=l)throw new TypeError("Invalid composite mode "+l+".");}else l="easing"==f?a.normalizeEasing(l):""+l;var g=f,h=l,l=d,n=g;if("display"!==n&&0!==n.lastIndexOf("animation",0)&&0!==n.lastIndexOf("transition",0))if(n=e[g])for(g in k.style[g]=h,g=void 0,n){var h=n[g],c=k.style[h];l[h]=h in t?t[h][c]||c:c}else l[g]=g in t?t[g][h]||h:h}return void 0==
d.offset&&(d.offset=null),void 0==d.easing&&(d.easing="linear"),d});g=!0;for(var b=-(1/0),d=0;d<f.length;d++){var l=f[d].offset;if(null!=l){if(l<b)throw new TypeError("Keyframes are not loosely sorted by offset. Sort or specify offsets.");b=l}else g=!1}return f=f.filter(function(b){return 0<=b.offset&&1>=b.offset}),g||n(),f}})(e,null);(function(a){var c={};a.isDeprecated=function(a,e,k,t){t=t?"are":"is";var g=new Date;e=new Date(e);return e.setMonth(e.getMonth()+3),!(g<e&&(a in c||console.warn("Web Animations: "+
a+" "+t+" deprecated and will stop working on "+e.toDateString()+". "+k),c[a]=!0,1))};a.deprecated=function(h,c,e,t){var g=t?"are":"is";if(a.isDeprecated(h,c,e,t))throw Error(h+" "+g+" no longer supported. "+e);}})(e);(function(){if(document.documentElement.animate){var a=document.documentElement.animate([],0),c=!0;if(a&&(c=!1,"play currentTime pause reverse playbackRate cancel finish startTime playState".split(" ").forEach(function(h){void 0===a[h]&&(c=!0)})),!c)return}!function(a,c,e){function h(a){for(var f=
{},b=0;b<a.length;b++)for(var d in a[b])if("offset"!=d&&"easing"!=d&&"composite"!=d){var l={offset:a[b].offset,easing:a[b].easing,value:a[b][d]};f[d]=f[d]||[];f[d].push(l)}for(var g in f)if(a=f[g],0!=a[0].offset||1!=a[a.length-1].offset)throw{type:DOMException.NOT_SUPPORTED_ERR,name:"NotSupportedError",message:"Partial keyframes are not supported"};return f}function g(g){var f=[],b;for(b in g)for(var d=g[b],l=0;l<d.length-1;l++){var h=l,q=l+1,n=d[h].offset,e=d[q].offset,t=n,m=e;0==l&&(t=-(1/0),0==
e&&(q=h));l==d.length-2&&(m=1/0,1==n&&(h=q));f.push({applyFrom:t,applyTo:m,startOffset:d[h].offset,endOffset:d[q].offset,easingFunction:a.parseEasingFunction(d[h].easing),property:b,interpolation:c.propertyInterpolation(b,d[h].value,d[q].value)})}return f.sort(function(b,a){return b.startOffset-a.startOffset}),f}c.convertEffectInput=function(n){n=a.normalizeKeyframes(n);var f=h(n),b=g(f);return function(a,l){if(null!=l)b.filter(function(b){return l>=b.applyFrom&&l<b.applyTo}).forEach(function(b){var d=
l-b.startOffset,f=b.endOffset-b.startOffset,d=0==f?0:b.easingFunction(d/f);c.apply(a,b.property,b.interpolation(d))});else for(var d in f)"offset"!=d&&"easing"!=d&&"composite"!=d&&c.clear(a,d)}}}(e,k,null);(function(a,c,e){function h(a){return a.replace(/-(.)/g,function(b,a){return a.toUpperCase()})}var g={};c.addPropertiesHandler=function(a,b,d){for(var f=0;f<d.length;f++){var c=a,n=b,e=h(d[f]);g[e]=g[e]||[];g[e].push([c,n])}};var n={backgroundColor:"transparent",backgroundPosition:"0% 0%",borderBottomColor:"currentColor",
borderBottomLeftRadius:"0px",borderBottomRightRadius:"0px",borderBottomWidth:"3px",borderLeftColor:"currentColor",borderLeftWidth:"3px",borderRightColor:"currentColor",borderRightWidth:"3px",borderSpacing:"2px",borderTopColor:"currentColor",borderTopLeftRadius:"0px",borderTopRightRadius:"0px",borderTopWidth:"3px",bottom:"auto",clip:"rect(0px, 0px, 0px, 0px)",color:"black",fontSize:"100%",fontWeight:"400",height:"auto",left:"auto",letterSpacing:"normal",lineHeight:"120%",marginBottom:"0px",marginLeft:"0px",
marginRight:"0px",marginTop:"0px",maxHeight:"none",maxWidth:"none",minHeight:"0px",minWidth:"0px",opacity:"1.0",outlineColor:"invert",outlineOffset:"0px",outlineWidth:"3px",paddingBottom:"0px",paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px",right:"auto",textIndent:"0px",textShadow:"0px 0px 0px transparent",top:"auto",transform:"",verticalAlign:"0px",visibility:"visible",width:"auto",wordSpacing:"normal",zIndex:"auto"};c.propertyInterpolation=function(f,b,d){var l=f;/-/.test(f)&&!a.isDeprecated("Hyphenated property names",
"2016-03-22","Use camelCase instead.",!0)&&(l=h(f));"initial"!=b&&"initial"!=d||("initial"==b&&(b=n[l]),"initial"==d&&(d=n[l]));f=b==d?[]:g[l];for(l=0;f&&l<f.length;l++){var e=f[l][0](b),q=f[l][0](d);if(void 0!==e&&void 0!==q&&(e=f[l][1](e,q))){var t=c.Interpolation.apply(null,e);return function(a){return 0==a?b:1==a?d:t(a)}}}return c.Interpolation(!1,!0,function(a){return a?d:b})}})(e,k,null);(function(a,c,e){function h(g){var h=a.calculateActiveDuration(g),f=function(b){return a.calculateIterationProgress(h,
b,g)};return f._totalDuration=g.delay+h+g.endDelay,f}c.KeyframeEffect=function(g,n,f,b){var d,l=h(a.normalizeTimingInput(f)),e=c.convertEffectInput(n);n=function(){e(g,d)};return n._update=function(b){return d=l(b),null!==d},n._clear=function(){e(g,null)},n._hasSameTarget=function(b){return g===b},n._target=g,n._totalDuration=l._totalDuration,n._id=b,n};c.NullEffect=function(a){var g=function(){a&&(a(),a=null)};return g._update=function(){return null},g._totalDuration=0,g._hasSameTarget=function(){return!1},
g}})(e,k,null);(function(a,c){function h(b,a,d){d.enumerable=!0;d.configurable=!0;Object.defineProperty(b,a,d)}function e(b){this._surrogateStyle=document.createElementNS("http://www.w3.org/1999/xhtml","div").style;this._style=b.style;this._length=0;this._isAnimatedProperty={};for(b=0;b<this._style.length;b++){var a=this._style[b];this._surrogateStyle[a]=this._style[a]}this._updateIndices()}function g(b){if(!b._webAnimationsPatchedStyle){var a=new e(b);try{h(b,"style",{get:function(){return a}})}catch(q){b.style._set=
function(a,d){b.style[a]=d},b.style._clear=function(a){b.style[a]=""}}b._webAnimationsPatchedStyle=b.style}}c={cssText:1,length:1,parentRule:1};var n={getPropertyCSSValue:1,getPropertyPriority:1,getPropertyValue:1,item:1,removeProperty:1,setProperty:1},f={removeProperty:1,setProperty:1};e.prototype={get cssText(){return this._surrogateStyle.cssText},set cssText(b){for(var a={},d=0;d<this._surrogateStyle.length;d++)a[this._surrogateStyle[d]]=!0;this._surrogateStyle.cssText=b;this._updateIndices();
for(d=0;d<this._surrogateStyle.length;d++)a[this._surrogateStyle[d]]=!0;for(var f in a)this._isAnimatedProperty[f]||this._style.setProperty(f,this._surrogateStyle.getPropertyValue(f))},get length(){return this._surrogateStyle.length},get parentRule(){return this._style.parentRule},_updateIndices:function(){for(;this._length<this._surrogateStyle.length;)Object.defineProperty(this,this._length,{configurable:!0,enumerable:!1,get:function(b){return function(){return this._surrogateStyle[b]}}(this._length)}),
this._length++;for(;this._length>this._surrogateStyle.length;)this._length--,Object.defineProperty(this,this._length,{configurable:!0,enumerable:!1,value:void 0})},_set:function(b,a){this._style[b]=a;this._isAnimatedProperty[b]=!0},_clear:function(b){this._style[b]=this._surrogateStyle[b];delete this._isAnimatedProperty[b]}};for(var b in n)e.prototype[b]=function(b,a){return function(){var d=this._surrogateStyle[b].apply(this._surrogateStyle,arguments);return a&&(this._isAnimatedProperty[arguments[0]]||
this._style[b].apply(this._style,arguments),this._updateIndices()),d}}(b,b in f);for(var d in document.documentElement.style)d in c||d in n||!function(b){h(e.prototype,b,{get:function(){return this._surrogateStyle[b]},set:function(a){this._surrogateStyle[b]=a;this._updateIndices();this._isAnimatedProperty[b]||(this._style[b]=a)}})}(d);a.apply=function(b,d,f){g(b);b.style._set(a.propertyName(d),f)};a.clear=function(b,d){b._webAnimationsPatchedStyle&&b.style._clear(a.propertyName(d))}})(k,null);(function(a){window.Element.prototype.animate=
function(h,c){var e="";return c&&c.id&&(e=c.id),a.timeline._play(a.KeyframeEffect(this,h,c,e))}})(k);(function(a,c){function h(a,g,c){if("number"==typeof a&&"number"==typeof g)return a*(1-c)+g*c;if("boolean"==typeof a&&"boolean"==typeof g)return.5>c?a:g;if(a.length==g.length){for(var f=[],b=0;b<a.length;b++)f.push(h(a[b],g[b],c));return f}throw"Mismatched interpolation arguments "+a+":"+g;}a.Interpolation=function(a,g,c){return function(f){return c(h(a,g,f))}}})(k,null);(function(a,c){c=function(){function a(a,
g){for(var c=[[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]],f=0;4>f;f++)for(var b=0;4>b;b++)for(var d=0;4>d;d++)c[f][b]+=g[f][d]*a[d][b];return c}return function(c,g,h,f,b){for(var d=[[1,0,0,0],[0,1,0,0],[0,0,1,0],[0,0,0,1]],l=0;4>l;l++)d[l][3]=b[l];for(l=0;3>l;l++)for(b=0;3>b;b++)d[3][l]+=c[b]*d[b][l];c=f[0];l=f[1];b=f[2];f=f[3];var e=[[1,0,0,0],[0,1,0,0],[0,0,1,0],[0,0,0,1]];e[0][0]=1-2*(l*l+b*b);e[0][1]=2*(c*l-b*f);e[0][2]=2*(c*b+l*f);e[1][0]=2*(c*l+b*f);e[1][1]=1-2*(c*c+b*b);e[1][2]=2*(l*b-c*f);e[2][0]=
2*(c*b-l*f);e[2][1]=2*(l*b+c*f);e[2][2]=1-2*(c*c+l*l);d=a(d,e);f=[[1,0,0,0],[0,1,0,0],[0,0,1,0],[0,0,0,1]];h[2]&&(f[2][1]=h[2],d=a(d,f));h[1]&&(f[2][1]=0,f[2][0]=h[0],d=a(d,f));h[0]&&(f[2][0]=0,f[1][0]=h[0],d=a(d,f));for(l=0;3>l;l++)for(b=0;3>b;b++)d[l][b]*=g[l];return 0==d[0][2]&&0==d[0][3]&&0==d[1][2]&&0==d[1][3]&&0==d[2][0]&&0==d[2][1]&&1==d[2][2]&&0==d[2][3]&&0==d[3][2]&&1==d[3][3]?[d[0][0],d[0][1],d[1][0],d[1][1],d[3][0],d[3][1]]:d[0].concat(d[1],d[2],d[3])}}();a.composeMatrix=c;a.quat=function(c,
h,g){var e=a.dot(c,h),e=Math.max(Math.min(e,1),-1),f=[];if(1===e)f=c;else for(var b=Math.acos(e),d=1*Math.sin(g*b)/Math.sqrt(1-e*e),l=0;4>l;l++)f.push(c[l]*(Math.cos(g*b)-e*d)+h[l]*d);return f}})(k,null);(function(a,c,e){a.sequenceNumber=0;var h=function(a,c,f){this.target=a;this.currentTime=c;this.timelineTime=f;this.type="finish";this.cancelable=this.bubbles=!1;this.currentTarget=a;this.defaultPrevented=!1;this.eventPhase=Event.AT_TARGET;this.timeStamp=Date.now()};c.Animation=function(c){this.id=
"";c&&c._id&&(this.id=c._id);this._sequenceNumber=a.sequenceNumber++;this._currentTime=0;this._startTime=null;this._paused=!1;this._playbackRate=1;this._finishedFlag=this._inTimeline=!0;this.onfinish=null;this._finishHandlers=[];this._effect=c;this._inEffect=this._effect._update(0);this._idle=!0;this._currentTimePending=!1};c.Animation.prototype={_ensureAlive:function(){0>this.playbackRate&&0===this.currentTime?this._inEffect=this._effect._update(-1):this._inEffect=this._effect._update(this.currentTime);
this._inTimeline||!this._inEffect&&this._finishedFlag||(this._inTimeline=!0,c.timeline._animations.push(this))},_tickCurrentTime:function(a,c){a!=this._currentTime&&(this._currentTime=a,this._isFinished&&!c&&(this._currentTime=0<this._playbackRate?this._totalDuration:0),this._ensureAlive())},get currentTime(){return this._idle||this._currentTimePending?null:this._currentTime},set currentTime(a){a=+a;isNaN(a)||(c.restart(),this._paused||null==this._startTime||(this._startTime=this._timeline.currentTime-
a/this._playbackRate),this._currentTimePending=!1,this._currentTime!=a&&(this._idle&&(this._idle=!1,this._paused=!0),this._tickCurrentTime(a,!0),c.applyDirtiedAnimation(this)))},get startTime(){return this._startTime},set startTime(a){a=+a;isNaN(a)||this._paused||this._idle||(this._startTime=a,this._tickCurrentTime((this._timeline.currentTime-this._startTime)*this.playbackRate),c.applyDirtiedAnimation(this))},get playbackRate(){return this._playbackRate},set playbackRate(a){if(a!=this._playbackRate){var h=
this.currentTime;this._playbackRate=a;this._startTime=null;"paused"!=this.playState&&"idle"!=this.playState&&(this._finishedFlag=!1,this._idle=!1,this._ensureAlive(),c.applyDirtiedAnimation(this));null!=h&&(this.currentTime=h)}},get _isFinished(){return!this._idle&&(0<this._playbackRate&&this._currentTime>=this._totalDuration||0>this._playbackRate&&0>=this._currentTime)},get _totalDuration(){return this._effect._totalDuration},get playState(){return this._idle?"idle":null==this._startTime&&!this._paused&&
0!=this.playbackRate||this._currentTimePending?"pending":this._paused?"paused":this._isFinished?"finished":"running"},_rewind:function(){if(0<=this._playbackRate)this._currentTime=0;else{if(!(this._totalDuration<1/0))throw new DOMException("Unable to rewind negative playback rate animation with infinite duration","InvalidStateError");this._currentTime=this._totalDuration}},play:function(){this._paused=!1;(this._isFinished||this._idle)&&(this._rewind(),this._startTime=null);this._idle=this._finishedFlag=
!1;this._ensureAlive();c.applyDirtiedAnimation(this)},pause:function(){this._isFinished||this._paused||this._idle?this._idle&&(this._rewind(),this._idle=!1):this._currentTimePending=!0;this._startTime=null;this._paused=!0},finish:function(){this._idle||(this.currentTime=0<this._playbackRate?this._totalDuration:0,this._startTime=this._totalDuration-this.currentTime,this._currentTimePending=!1,c.applyDirtiedAnimation(this))},cancel:function(){this._inEffect&&(this._inEffect=!1,this._idle=!0,this._paused=
!1,this._isFinished=!0,this._finishedFlag=!0,this._currentTime=0,this._startTime=null,this._effect._update(null),c.applyDirtiedAnimation(this))},reverse:function(){this.playbackRate*=-1;this.play()},addEventListener:function(a,c){"function"==typeof c&&"finish"==a&&this._finishHandlers.push(c)},removeEventListener:function(a,c){"finish"==a&&(a=this._finishHandlers.indexOf(c),0<=a&&this._finishHandlers.splice(a,1))},_fireEvents:function(a){if(this._isFinished){if(!this._finishedFlag){var c=new h(this,
this._currentTime,a),f=this._finishHandlers.concat(this.onfinish?[this.onfinish]:[]);setTimeout(function(){f.forEach(function(b){b.call(c.target,c)})},0);this._finishedFlag=!0}}else this._finishedFlag=!1},_tick:function(a,c){this._idle||this._paused||(null==this._startTime?c&&(this.startTime=a-this._currentTime/this.playbackRate):this._isFinished||this._tickCurrentTime((a-this._startTime)*this.playbackRate));c&&(this._currentTimePending=!1,this._fireEvents(a))},get _needsTick(){return this.playState in
{pending:1,running:1}||!this._finishedFlag},_targetAnimations:function(){var a=this._effect._target;return a._activeAnimations||(a._activeAnimations=[]),a._activeAnimations},_markTarget:function(){var a=this._targetAnimations();-1===a.indexOf(this)&&a.push(this)},_unmarkTarget:function(){var a=this._targetAnimations(),c=a.indexOf(this);-1!==c&&a.splice(c,1)}}})(e,k,null);(function(a,c,e){function h(a){var d=l;l=[];a<m.currentTime&&(a=m.currentTime);m._animations.sort(g);m._animations=b(a,!0,m._animations)[0];
d.forEach(function(b){b[1](a)});f()}function g(b,a){return b._sequenceNumber-a._sequenceNumber}function n(){this._animations=[];this.currentTime=window.performance&&performance.now?performance.now():0}function f(){y.forEach(function(b){b()});y.length=0}function b(b,a,d){u=!0;x=!1;c.timeline.currentTime=b;q=!1;var f=[],h=[],e=[],l=[];return d.forEach(function(d){d._tick(b,a);d._inEffect?(h.push(d._effect),d._markTarget()):(f.push(d._effect),d._unmarkTarget());d._needsTick&&(q=!0);var c=d._inEffect||
d._needsTick;(d._inTimeline=c)?e.push(d):l.push(d)}),y.push.apply(y,f),y.push.apply(y,h),q&&requestAnimationFrame(function(){}),u=!1,[e,l]}var d=window.requestAnimationFrame,l=[],k=0;window.requestAnimationFrame=function(b){var a=k++;return 0==l.length&&d(h),l.push([a,b]),a};window.cancelAnimationFrame=function(b){l.forEach(function(a){a[0]==b&&(a[1]=function(){})})};n.prototype={_play:function(b){b._timing=a.normalizeTimingInput(b.timing);b=new c.Animation(b);return b._idle=!1,b._timeline=this,this._animations.push(b),
c.restart(),c.applyDirtiedAnimation(b),b}};var q=!1,x=!1;c.restart=function(){return q||(q=!0,requestAnimationFrame(function(){}),x=!0),x};c.applyDirtiedAnimation=function(a){u||(a._markTarget(),a=a._targetAnimations(),a.sort(g),b(c.timeline.currentTime,!1,a.slice())[1].forEach(function(b){b=m._animations.indexOf(b);-1!==b&&m._animations.splice(b,1)}),f())};var y=[],u=!1,m=new n;c.timeline=m})(e,k,null);(function(a,c){function h(b,a){for(var d=0,c=0;c<b.length;c++)d+=b[c]*a[c];return d}function e(b,
a){return[b[0]*a[0]+b[4]*a[1]+b[8]*a[2]+b[12]*a[3],b[1]*a[0]+b[5]*a[1]+b[9]*a[2]+b[13]*a[3],b[2]*a[0]+b[6]*a[1]+b[10]*a[2]+b[14]*a[3],b[3]*a[0]+b[7]*a[1]+b[11]*a[2]+b[15]*a[3],b[0]*a[4]+b[4]*a[5]+b[8]*a[6]+b[12]*a[7],b[1]*a[4]+b[5]*a[5]+b[9]*a[6]+b[13]*a[7],b[2]*a[4]+b[6]*a[5]+b[10]*a[6]+b[14]*a[7],b[3]*a[4]+b[7]*a[5]+b[11]*a[6]+b[15]*a[7],b[0]*a[8]+b[4]*a[9]+b[8]*a[10]+b[12]*a[11],b[1]*a[8]+b[5]*a[9]+b[9]*a[10]+b[13]*a[11],b[2]*a[8]+b[6]*a[9]+b[10]*a[10]+b[14]*a[11],b[3]*a[8]+b[7]*a[9]+b[11]*a[10]+
b[15]*a[11],b[0]*a[12]+b[4]*a[13]+b[8]*a[14]+b[12]*a[15],b[1]*a[12]+b[5]*a[13]+b[9]*a[14]+b[13]*a[15],b[2]*a[12]+b[6]*a[13]+b[10]*a[14]+b[14]*a[15],b[3]*a[12]+b[7]*a[13]+b[11]*a[14]+b[15]*a[15]]}function g(a){return 2*((a.deg||0)/360+(a.grad||0)/400+(a.turn||0))*Math.PI+(a.rad||0)}function n(a){switch(a.t){case "rotatex":return a=g(a.d[0]),[1,0,0,0,0,Math.cos(a),Math.sin(a),0,0,-Math.sin(a),Math.cos(a),0,0,0,0,1];case "rotatey":return a=g(a.d[0]),[Math.cos(a),0,-Math.sin(a),0,0,1,0,0,Math.sin(a),
0,Math.cos(a),0,0,0,0,1];case "rotate":case "rotatez":return a=g(a.d[0]),[Math.cos(a),Math.sin(a),0,0,-Math.sin(a),Math.cos(a),0,0,0,0,1,0,0,0,0,1];case "rotate3d":var b=a.d[0],c=a.d[1],f=a.d[2];a=g(a.d[3]);var h=b*b+c*c+f*f;0===h?(b=1,f=c=0):1!==h&&(h=Math.sqrt(h),b/=h,c/=h,f/=h);h=Math.sin(a/2);a=h*Math.cos(a/2);h*=h;return[1-2*(c*c+f*f)*h,2*(b*c*h+f*a),2*(b*f*h-c*a),0,2*(b*c*h-f*a),1-2*(b*b+f*f)*h,2*(c*f*h+b*a),0,2*(b*f*h+c*a),2*(c*f*h-b*a),1-2*(b*b+c*c)*h,0,0,0,0,1];case "scale":return[a.d[0],
0,0,0,0,a.d[1],0,0,0,0,1,0,0,0,0,1];case "scalex":return[a.d[0],0,0,0,0,1,0,0,0,0,1,0,0,0,0,1];case "scaley":return[1,0,0,0,0,a.d[0],0,0,0,0,1,0,0,0,0,1];case "scalez":return[1,0,0,0,0,1,0,0,0,0,a.d[0],0,0,0,0,1];case "scale3d":return[a.d[0],0,0,0,0,a.d[1],0,0,0,0,a.d[2],0,0,0,0,1];case "skew":return b=g(a.d[0]),c=g(a.d[1]),[1,Math.tan(c),0,0,Math.tan(b),1,0,0,0,0,1,0,0,0,0,1];case "skewx":return a=g(a.d[0]),[1,0,0,0,Math.tan(a),1,0,0,0,0,1,0,0,0,0,1];case "skewy":return a=g(a.d[0]),[1,Math.tan(a),
0,0,0,1,0,0,0,0,1,0,0,0,0,1];case "translate":return b=a.d[0].px||0,c=a.d[1].px||0,[1,0,0,0,0,1,0,0,0,0,1,0,b,c,0,1];case "translatex":return b=a.d[0].px||0,[1,0,0,0,0,1,0,0,0,0,1,0,b,0,0,1];case "translatey":return c=a.d[0].px||0,[1,0,0,0,0,1,0,0,0,0,1,0,0,c,0,1];case "translatez":return f=a.d[0].px||0,[1,0,0,0,0,1,0,0,0,0,1,0,0,0,f,1];case "translate3d":return b=a.d[0].px||0,c=a.d[1].px||0,f=a.d[2].px||0,[1,0,0,0,0,1,0,0,0,0,1,0,b,c,f,1];case "perspective":return[1,0,0,0,0,1,0,0,0,0,1,a.d[0].px?
-1/a.d[0].px:0,0,0,0,1];case "matrix":return[a.d[0],a.d[1],0,0,a.d[2],a.d[3],0,0,0,0,1,0,a.d[4],a.d[5],0,1];case "matrix3d":return a.d}}var f=function(){function a(a){return a[0][0]*a[1][1]*a[2][2]+a[1][0]*a[2][1]*a[0][2]+a[2][0]*a[0][1]*a[1][2]-a[0][2]*a[1][1]*a[2][0]-a[1][2]*a[2][1]*a[0][0]-a[2][2]*a[0][1]*a[1][0]}function c(a){var b=f(a);return[a[0]/b,a[1]/b,a[2]/b]}function f(a){return Math.sqrt(a[0]*a[0]+a[1]*a[1]+a[2]*a[2])}function e(a,b,c,f){return[c*a[0]+f*b[0],c*a[1]+f*b[1],c*a[2]+f*b[2]]}
return function(b){b=[b.slice(0,4),b.slice(4,8),b.slice(8,12),b.slice(12,16)];if(1!==b[3][3])return null;for(var d=[],g=0;4>g;g++)d.push(b[g].slice());for(g=0;3>g;g++)d[g][3]=0;if(0===a(d))return!1;var l;l=[];if(b[0][3]||b[1][3]||b[2][3]){l.push(b[0][3]);l.push(b[1][3]);l.push(b[2][3]);l.push(b[3][3]);for(var m=1/a(d),n=d[0][0],k=d[0][1],g=d[0][2],q=d[1][0],t=d[1][1],u=d[1][2],p=d[2][0],v=d[2][1],r=d[2][2],m=[[(t*r-u*v)*m,(g*v-k*r)*m,(k*u-g*t)*m,0],[(u*p-q*r)*m,(n*r-g*p)*m,(g*q-n*u)*m,0],[(q*v-t*
p)*m,(p*k-n*v)*m,(n*t-k*q)*m,0]],n=[],k=0;3>k;k++){for(q=g=0;3>q;q++)g+=d[3][q]*m[q][k];n.push(g)}d=(n.push(1),m.push(n),m);d=[[d[0][0],d[1][0],d[2][0],d[3][0]],[d[0][1],d[1][1],d[2][1],d[3][1]],[d[0][2],d[1][2],d[2][2],d[3][2]],[d[0][3],d[1][3],d[2][3],d[3][3]]];m=[];for(n=0;4>n;n++){for(g=k=0;4>g;g++)k+=l[g]*d[g][n];m.push(k)}l=m}else l=[0,0,0,1];d=b[3].slice(0,3);m=[];m.push(b[0].slice(0,3));n=[];n.push(f(m[0]));m[0]=c(m[0]);k=[];m.push(b[1].slice(0,3));k.push(h(m[0],m[1]));m[1]=e(m[1],m[0],1,
-k[0]);n.push(f(m[1]));m[1]=c(m[1]);k[0]/=n[1];m.push(b[2].slice(0,3));k.push(h(m[0],m[2]));m[2]=e(m[2],m[0],1,-k[1]);k.push(h(m[1],m[2]));m[2]=e(m[2],m[1],1,-k[2]);n.push(f(m[2]));m[2]=c(m[2]);k[1]/=n[2];k[2]/=n[2];b=m[1];g=m[2];if(0>h(m[0],[b[1]*g[2]-b[2]*g[1],b[2]*g[0]-b[0]*g[2],b[0]*g[1]-b[1]*g[0]]))for(g=0;3>g;g++)n[g]*=-1,m[g][0]*=-1,m[g][1]*=-1,m[g][2]*=-1;var w,A;b=m[0][0]+m[1][1]+m[2][2]+1;return 1E-4<b?(w=.5/Math.sqrt(b),A=[(m[2][1]-m[1][2])*w,(m[0][2]-m[2][0])*w,(m[1][0]-m[0][1])*w,.25/
w]):m[0][0]>m[1][1]&&m[0][0]>m[2][2]?(w=2*Math.sqrt(1+m[0][0]-m[1][1]-m[2][2]),A=[.25*w,(m[0][1]+m[1][0])/w,(m[0][2]+m[2][0])/w,(m[2][1]-m[1][2])/w]):m[1][1]>m[2][2]?(w=2*Math.sqrt(1+m[1][1]-m[0][0]-m[2][2]),A=[(m[0][1]+m[1][0])/w,.25*w,(m[1][2]+m[2][1])/w,(m[0][2]-m[2][0])/w]):(w=2*Math.sqrt(1+m[2][2]-m[0][0]-m[1][1]),A=[(m[0][2]+m[2][0])/w,(m[1][2]+m[2][1])/w,.25*w,(m[1][0]-m[0][1])/w]),[d,n,k,A,l]}}();a.dot=h;a.makeMatrixDecomposition=function(a){return[f(0===a.length?[1,0,0,0,0,1,0,0,0,0,1,0,
0,0,0,1]:a.map(n).reduce(e))]}})(k,null);(function(a){function c(a,c){var b=a.exec(c);if(b)return b=a.ignoreCase?b[0].toLowerCase():b[0],[b,c.substr(b.length)]}function h(a,c){c=c.replace(/^\s*/,"");if(a=a(c))return[a[0],a[1].replace(/^\s*/,"")]}function e(a,c){for(var b=a,d=c;b&&d;)b>d?b%=d:d%=b;return a*c/(b+d)}function g(a,c,b,d,h){for(var f=[],g=[],l=[],n=e(d.length,h.length),k=0;k<n;k++){var m=c(d[k%d.length],h[k%h.length]);if(!m)return;f.push(m[0]);g.push(m[1]);l.push(m[2])}return[f,g,function(c){c=
c.map(function(a,b){return l[b](a)}).join(b);return a?a(c):c}]}a.consumeToken=c;a.consumeTrimmed=h;a.consumeRepeated=function(a,f,b){a=h.bind(null,a);for(var d=[];;){var g=a(b);if(!g||(d.push(g[0]),b=g[1],g=c(f,b),!g||""==g[1]))return[d,b];b=g[1]}};a.consumeParenthesised=function(a,c){for(var b=0,d=0;d<c.length&&(!/\s|,/.test(c[d])||0!=b);d++)if("("==c[d])b++;else if(")"==c[d]&&(b--,0==b&&d++,0>=b))break;a=a(c.substr(0,d));return void 0==a?void 0:[a,c.substr(d)]};a.ignore=function(a){return function(c){c=
a(c);return c&&(c[0]=void 0),c}};a.optional=function(a,c){return function(b){var d=a(b);return d?d:[c,b]}};a.consumeList=function(c,f){for(var b=[],d=0;d<c.length;d++){f=a.consumeTrimmed(c[d],f);if(!f||""==f[0])return;void 0!==f[0]&&b.push(f[0]);f=f[1]}if(""==f)return b};a.mergeNestedRepeated=g.bind(null,null);a.mergeWrappedNestedRepeated=g;a.mergeList=function(a,c,b){for(var d=[],f=[],h=[],g=0,e=0;e<b.length;e++)if("function"==typeof b[e]){var k=b[e](a[g],c[g++]);d.push(k[0]);f.push(k[1]);h.push(k[2])}else!function(a){d.push(!1);
f.push(!1);h.push(function(){return b[a]})}(e);return[d,f,function(a){for(var b="",c=0;c<a.length;c++)b+=h[c](a[c]);return b}]}})(k);(function(a){function c(c){var h={inset:!1,lengths:[],color:null};if((c=a.consumeRepeated(function(c){var f=a.consumeToken(/^inset/i,c);return f?(h.inset=!0,f):(f=a.consumeLengthOrPercent(c))?(h.lengths.push(f[0]),f):(f=a.consumeColor(c))?(h.color=f[0],f):void 0},/^/,c))&&c[0].length)return[h,c[1]]}var h=function(c,h,e,f){function b(a){return{inset:a,color:[0,0,0,0],
lengths:[{px:0},{px:0},{px:0},{px:0}]}}for(var d=[],g=[],k=0;k<e.length||k<f.length;k++){var n=e[k]||b(f[k].inset),t=f[k]||b(e[k].inset);d.push(n);g.push(t)}return a.mergeNestedRepeated(c,h,d,g)}.bind(null,function(c,h){for(;c.lengths.length<Math.max(c.lengths.length,h.lengths.length);)c.lengths.push({px:0});for(;h.lengths.length<Math.max(c.lengths.length,h.lengths.length);)h.lengths.push({px:0});if(c.inset==h.inset&&!!c.color==!!h.color){for(var g,f=[],b=[[],0],d=[[],0],e=0;e<c.lengths.length;e++){var k=
a.mergeDimensions(c.lengths[e],h.lengths[e],2==e);b[0].push(k[0]);d[0].push(k[1]);f.push(k[2])}c.color&&h.color&&(h=a.mergeColors(c.color,h.color),b[1]=h[0],d[1]=h[1],g=h[2]);return[b,d,function(a){for(var b=c.inset?"inset ":" ",d=0;d<f.length;d++)b+=f[d](a[0][d])+" ";return g&&(b+=g(a[1])),b}]}},", ");a.addPropertiesHandler(function(h){if((h=a.consumeRepeated(c,/^,/,h))&&""==h[1])return h[0]},h,["box-shadow","text-shadow"])})(k);(function(a,c){function h(a){return a.toFixed(3).replace(".000","")}
function e(a,b,c){return Math.min(b,Math.max(a,c))}function g(a){if(/^\s*[-+]?(\d*\.)?\d+\s*$/.test(a))return Number(a)}function k(a,b){return function(c,f){return[c,f,function(c){return h(e(a,b,c))}]}}a.clamp=e;a.addPropertiesHandler(g,k(0,1/0),["border-image-width","line-height"]);a.addPropertiesHandler(g,k(0,1),["opacity","shape-image-threshold"]);a.addPropertiesHandler(g,function(a,b){if(0!=a)return k(0,1/0)(a,b)},["flex-grow","flex-shrink"]);a.addPropertiesHandler(g,function(a,b){return[a,b,
function(a){return Math.round(e(1,1/0,a))}]},["orphans","widows"]);a.addPropertiesHandler(g,function(a,b){return[a,b,Math.round]},["z-index"]);a.parseNumber=g;a.mergeNumbers=function(a,b){return[a,b,h]};a.numberToString=h})(k,null);(function(a,c){a.addPropertiesHandler(String,function(a,c){if("visible"==a||"visible"==c)return[0,1,function(h){return 0>=h?a:1<=h?c:"visible"}]},["visibility"])})(k);(function(a,c){function h(a){a=a.trim();g.fillStyle="#000";g.fillStyle=a;var c=g.fillStyle;if(g.fillStyle=
"#fff",g.fillStyle=a,c==g.fillStyle)return g.fillRect(0,0,1,1),a=g.getImageData(0,0,1,1).data,g.clearRect(0,0,1,1),c=a[3]/255,[a[0]*c,a[1]*c,a[2]*c,c]}function e(c,f){return[c,f,function(b){if(b[3])for(var c=0;3>c;c++)b[c]=Math.round(Math.max(0,Math.min(255,b[c]/b[3])));return b[3]=a.numberToString(a.clamp(0,1,b[3])),"rgba("+b.join(",")+")"}]}c=document.createElementNS("http://www.w3.org/1999/xhtml","canvas");c.width=c.height=1;var g=c.getContext("2d");a.addPropertiesHandler(h,e,"background-color border-bottom-color border-left-color border-right-color border-top-color color outline-color text-decoration-color".split(" "));
a.consumeColor=a.consumeParenthesised.bind(null,h);a.mergeColors=e})(k,null);(function(a,c){function h(a,b){if(b=b.trim().toLowerCase(),"0"==b&&0<="px".search(a))return{px:0};if(/^[^(]*$|^calc/.test(b)){b=b.replace(/calc\(/g,"(");var c={};b=b.replace(a,function(a){return c[a]=null,"U"+a});a="U("+a.source+")";for(var d=b.replace(/[-+]?(\d*\.)?\d+/g,"N").replace(new RegExp("N"+a,"g"),"D").replace(/\s[+-]\s/g,"O").replace(/\s/g,""),f=[/N\*(D)/g,/(N|D)[*\/]N/g,/(N|D)O\1/g,/\((N|D)\)/g],h=0;h<f.length;)f[h].test(d)?
(d=d.replace(f[h],"$1"),h=0):h++;if("D"==d){for(var e in c){d=eval(b.replace(new RegExp("U"+e,"g"),"").replace(new RegExp(a,"g"),"*0"));if(!isFinite(d))return;c[e]=d}return c}}}function e(a,b){return g(a,b,!0)}function g(b,c,f){var d,h=[];for(d in b)h.push(d);for(d in c)0>h.indexOf(d)&&h.push(d);return b=h.map(function(a){return b[a]||0}),c=h.map(function(a){return c[a]||0}),[b,c,function(b){var c=b.map(function(c,d){return 1==b.length&&f&&(c=Math.max(c,0)),a.numberToString(c)+h[d]}).join(" + ");
return 1<b.length?"calc("+c+")":c}]}var k=h.bind(null,/px|em|ex|ch|rem|vw|vh|vmin|vmax|cm|mm|in|pt|pc/g);c=h.bind(null,/px|em|ex|ch|rem|vw|vh|vmin|vmax|cm|mm|in|pt|pc|%/g);var f=h.bind(null,/deg|rad|grad|turn/g);a.parseLength=k;a.parseLengthOrPercent=c;a.consumeLengthOrPercent=a.consumeParenthesised.bind(null,c);a.parseAngle=f;a.mergeDimensions=g;var k=a.consumeParenthesised.bind(null,k),k=a.consumeRepeated.bind(void 0,k,/^/),b=a.consumeRepeated.bind(void 0,k,/^,/);a.consumeSizePairList=b;k=a.mergeNestedRepeated.bind(void 0,
e," ");f=a.mergeNestedRepeated.bind(void 0,k,",");a.mergeNonNegativeSizePair=k;a.addPropertiesHandler(function(a){if((a=b(a))&&""==a[1])return a[0]},f,["background-size"]);a.addPropertiesHandler(c,e,"border-bottom-width border-image-width border-left-width border-right-width border-top-width flex-basis font-size height line-height max-height max-width outline-width width".split(" "));a.addPropertiesHandler(c,g,"border-bottom-left-radius border-bottom-right-radius border-top-left-radius border-top-right-radius bottom left letter-spacing margin-bottom margin-left margin-right margin-top min-height min-width outline-offset padding-bottom padding-left padding-right padding-top perspective right shape-margin text-indent top vertical-align word-spacing".split(" "))})(k,
null);(function(a,c){function h(c){return a.consumeLengthOrPercent(c)||a.consumeToken(/^auto/,c)}function e(c){if((c=a.consumeList([a.ignore(a.consumeToken.bind(null,/^rect/)),a.ignore(a.consumeToken.bind(null,/^\(/)),a.consumeRepeated.bind(null,h,/^,/),a.ignore(a.consumeToken.bind(null,/^\)/))],c))&&4==c[0].length)return c[0]}c=a.mergeWrappedNestedRepeated.bind(null,function(a){return"rect("+a+")"},function(c,h){return"auto"==c||"auto"==h?[!0,!1,function(f){f=f?c:h;if("auto"==f)return"auto";f=a.mergeDimensions(f,
f);return f[2](f[0])}]:a.mergeDimensions(c,h)},", ");a.parseBox=e;a.mergeBoxes=c;a.addPropertiesHandler(e,c,["clip"])})(k,null);(function(a,c){function e(a){return function(c){var d=0;return a.map(function(a){return a===b?c[d++]:a})}}function h(a){return a}function g(a){return a.toFixed(6).replace(".000000","")}function k(b,c){if(b.decompositionPair!==c){b.decompositionPair=c;var d=a.makeMatrixDecomposition(b)}if(c.decompositionPair!==b){c.decompositionPair=b;var f=a.makeMatrixDecomposition(c)}return null==
d[0]||null==f[0]?[[!1],[!0],function(a){return a?c[0].d:b[0].d}]:(d[0].push(0),f[0].push(1),[d,f,function(b){var c=a.quat(d[0][3],f[0][3],b[5]);return a.composeMatrix(b[0],b[1],b[2],c,b[4]).map(g).join(",")}])}function f(a){return a.replace(/(x|y|z|3d)?$/,"3d")}var b=null,d={px:0},l={deg:0},p={matrix:["NNNNNN",[b,b,0,0,b,b,0,0,0,0,1,0,b,b,0,1],h],matrix3d:["NNNNNNNNNNNNNNNN",h],rotate:["A"],rotatex:["A"],rotatey:["A"],rotatez:["A"],rotate3d:["NNNA"],perspective:["L"],scale:["Nn",e([b,b,1]),h],scalex:["N",
e([b,1,1]),e([b,1])],scaley:["N",e([1,b,1]),e([1,b])],scalez:["N",e([1,1,b])],scale3d:["NNN",h],skew:["Aa",null,h],skewx:["A",null,e([b,l])],skewy:["A",null,e([l,b])],translate:["Tt",e([b,b,d]),h],translatex:["T",e([b,d,d]),e([b,d])],translatey:["T",e([d,b,d]),e([d,b])],translatez:["L",e([d,d,b])],translate3d:["TTL",h]};a.addPropertiesHandler(function(b){if(b=b.toLowerCase().trim(),"none"==b)return[];for(var c,f=/\s*(\w+)\(([^)]*)\)/g,e=[],h=0;(c=f.exec(b))&&c.index==h;){var h=c.index+c[0].length,
g=c[1],k=p[g];if(!k)break;c=c[2].split(",");k=k[0];if(k.length<c.length)break;for(var n=[],q=0;q<k.length;q++){var r,v=c[q],u=k[q];if(r=v?{A:function(b){return"0"==b.trim()?l:a.parseAngle(b)},N:a.parseNumber,T:a.parseLengthOrPercent,L:a.parseLength}[u.toUpperCase()](v):{a:l,n:n[0],t:d}[u],void 0===r)return;n.push(r)}if(e.push({t:g,d:n}),f.lastIndex==b.length)return e}},function(b,c){var d=a.makeMatrixDecomposition&&!0,e=!1;if(!b.length||!c.length){b.length||(e=!0,b=c,c=[]);for(var h=0;h<b.length;h++){var g=
b[h].t,l=b[h].d,n="scale"==g.substr(0,5)?1:0;c.push({t:g,d:l.map(function(a){if("number"==typeof a)return n;var b={},c;for(c in a)b[c]=n;return b})})}}var l=[],r=[],v=[];if(b.length!=c.length){if(!d)return;var u=k(b,c),l=[u[0]],r=[u[1]],v=[["matrix",[u[2]]]]}else for(h=0;h<b.length;h++){var g=b[h].t,q=c[h].t,t=b[h].d,w=c[h].d,u=p[g],x=p[q];if("perspective"==g&&"perspective"==q||!("matrix"!=g&&"matrix3d"!=g||"matrix"!=q&&"matrix3d"!=q)){if(!d)return;u=k([b[h]],[c[h]]);l.push(u[0]);r.push(u[1]);v.push(["matrix",
[u[2]]])}else{if(g!=q)if(u[2]&&x[2]&&g.replace(/[xy]/,"")==q.replace(/[xy]/,""))g=g.replace(/[xy]/,""),t=u[2](t),w=x[2](w);else{if(!u[1]||!x[1]||f(g)!=f(q)){if(!d)return;u=k(b,c);l=[u[0]];r=[u[1]];v=[["matrix",[u[2]]]];break}g=f(g);t=u[1](t);w=x[1](w)}for(var x=[],q=[],D=[],z=0;z<t.length;z++)u=("number"==typeof t[z]?a.mergeNumbers:a.mergeDimensions)(t[z],w[z]),x[z]=u[0],q[z]=u[1],D.push(u[2]);l.push(x);r.push(q);v.push([g,D])}}e&&(r=l=r);return[l,r,function(a){return a.map(function(a,b){a=a.map(function(a,
c){return v[b][1][c](a)}).join(",");return"matrix"==v[b][0]&&16==a.split(",").length&&(v[b][0]="matrix3d"),v[b][0]+"("+a+")"}).join(" ")}]},["transform"])})(k,null);(function(a){function c(c){return c=100*Math.round(c/100),c=a.clamp(100,900,c),400===c?"normal":700===c?"bold":String(c)}a.addPropertiesHandler(function(a){a=Number(a);if(!(isNaN(a)||100>a||900<a||0!==a%100))return a},function(a,e){return[a,e,c]},["font-weight"])})(k);(function(a){function c(c){return a.consumeToken(/^(left|center|right|top|bottom)\b/i,
c)||a.consumeLengthOrPercent(c)}function e(f,b){if((b=a.consumeRepeated(c,/^/,b))&&""==b[1]&&(b=b[0],b[0]=b[0]||"center",b[1]=b[1]||"center",3==f&&(b[2]=b[2]||{px:0}),b.length==f)){if(/top|bottom/.test(b[0])||/left|right/.test(b[1]))f=b[0],b[0]=b[1],b[1]=f;if(/left|right|center|Object/.test(b[0])&&/top|bottom|center|Object/.test(b[1]))return b.map(function(a){return"object"==typeof a?a:g[a]})}}function h(f){if(f=a.consumeRepeated(c,/^/,f)){for(var b=f[0],d=[{"%":50},{"%":50}],e=0,h=!1,k=0;k<b.length;k++){var n=
b[k];if("string"==typeof n)h=/bottom|right/.test(n),e={left:0,right:0,center:e,top:1,bottom:1}[n],d[e]=g[n],"center"==n&&e++;else{if(h){var h=n,n={},p=void 0;for(p in h)n[p]=-h[p];n["%"]=(n["%"]||0)+100}d[e]=n;e++;h=!1}}return[d,f[1]]}}var g={left:{"%":0},center:{"%":50},right:{"%":100},top:{"%":0},bottom:{"%":100}},k=a.mergeNestedRepeated.bind(null,a.mergeDimensions," ");a.addPropertiesHandler(e.bind(null,3),k,["transform-origin"]);a.addPropertiesHandler(e.bind(null,2),k,["perspective-origin"]);
a.consumePosition=h;a.mergeOffsetList=k;k=a.mergeNestedRepeated.bind(null,k,", ");a.addPropertiesHandler(function(c){if((c=a.consumeRepeated(h,/^,/,c))&&""==c[1])return c[0]},k,["background-position","object-position"])})(k);(function(a){var c=a.consumeParenthesised.bind(null,a.parseLengthOrPercent),e=a.consumeRepeated.bind(void 0,c,/^/),h=a.mergeNestedRepeated.bind(void 0,a.mergeDimensions," "),g=a.mergeNestedRepeated.bind(void 0,h,",");a.addPropertiesHandler(function(g){var f=a.consumeToken(/^circle/,
g);return f&&f[0]?["circle"].concat(a.consumeList([a.ignore(a.consumeToken.bind(void 0,/^\(/)),c,a.ignore(a.consumeToken.bind(void 0,/^at/)),a.consumePosition,a.ignore(a.consumeToken.bind(void 0,/^\)/))],f[1])):(f=a.consumeToken(/^ellipse/,g))&&f[0]?["ellipse"].concat(a.consumeList([a.ignore(a.consumeToken.bind(void 0,/^\(/)),e,a.ignore(a.consumeToken.bind(void 0,/^at/)),a.consumePosition,a.ignore(a.consumeToken.bind(void 0,/^\)/))],f[1])):(g=a.consumeToken(/^polygon/,g))&&g[0]?["polygon"].concat(a.consumeList([a.ignore(a.consumeToken.bind(void 0,
/^\(/)),a.optional(a.consumeToken.bind(void 0,/^nonzero\s*,|^evenodd\s*,/),"nonzero,"),a.consumeSizePairList,a.ignore(a.consumeToken.bind(void 0,/^\)/))],g[1])):void 0},function(c,e){if(c[0]===e[0])return"circle"==c[0]?a.mergeList(c.slice(1),e.slice(1),["circle(",a.mergeDimensions," at ",a.mergeOffsetList,")"]):"ellipse"==c[0]?a.mergeList(c.slice(1),e.slice(1),["ellipse(",a.mergeNonNegativeSizePair," at ",a.mergeOffsetList,")"]):"polygon"==c[0]&&c[1]==e[1]?a.mergeList(c.slice(2),e.slice(2),["polygon(",
c[1],g,")"]):void 0},["shape-outside"])})(k);(function(a,c){function e(a,c){c.concat([a]).forEach(function(c){c in document.documentElement.style&&(h[a]=c)})}var h={};e("transform",["webkitTransform","msTransform"]);e("transformOrigin",["webkitTransformOrigin"]);e("perspective",["webkitPerspective"]);e("perspectiveOrigin",["webkitPerspectiveOrigin"]);a.propertyName=function(a){return h[a]||a}})(k,null)})();!function(){if(void 0===document.createElement("div").animate([]).oncancel){var a;a=window.performance&&
performance.now?function(){return performance.now()}:function(){return Date.now()};var c=function(a,c,e){this.target=a;this.currentTime=c;this.timelineTime=e;this.type="cancel";this.cancelable=this.bubbles=!1;this.currentTarget=a;this.defaultPrevented=!1;this.eventPhase=Event.AT_TARGET;this.timeStamp=Date.now()},e=window.Element.prototype.animate;window.Element.prototype.animate=function(h,k){h=e.call(this,h,k);h._cancelHandlers=[];h.oncancel=null;var p=h.cancel;h.cancel=function(){p.call(this);var e=
new c(this,null,a()),b=this._cancelHandlers.concat(this.oncancel?[this.oncancel]:[]);setTimeout(function(){b.forEach(function(a){a.call(e.target,e)})},0)};var g=h.addEventListener;h.addEventListener=function(a,b){"function"==typeof b&&"cancel"==a?this._cancelHandlers.push(b):g.call(this,a,b)};var n=h.removeEventListener;return h.removeEventListener=function(a,b){"cancel"==a?(a=this._cancelHandlers.indexOf(b),0<=a&&this._cancelHandlers.splice(a,1)):n.call(this,a,b)},h}}}();(function(a){var c=document.documentElement,
e=null,k=!1;try{var p="0"==getComputedStyle(c).getPropertyValue("opacity")?"1":"0",e=c.animate({opacity:[p,p]},{duration:1});e.currentTime=0;k=getComputedStyle(c).getPropertyValue("opacity")==p}catch(g){}finally{e&&e.cancel()}if(!k){var t=window.Element.prototype.animate;window.Element.prototype.animate=function(c,e){return window.Symbol&&Symbol.iterator&&Array.prototype.from&&c[Symbol.iterator]&&(c=Array.from(c)),Array.isArray(c)||null===c||(c=a.convertToArrayForm(c)),t.call(this,c,e)}}})(e);c.true=
a}({},function(){return this}());function create(a,c,e){a=document.createElement(a);c&&(a.id=c);e&&(a.onclick=e);return a}function createClass(a,c,e){a=document.createElement(a);c&&(a.className=c);e&&(a.onclick=e);return a}function iterableToArray(a){for(var c=[],e=0;e<a.length;e++)c.push(a[e]);return c}function applyStyles(a,c){for(var e in c)e in a.style&&(a.style[e]=c[e])}function hide(a){a.originalDisplay=a.style.display&&("none"==a.style.display?"":a.style.display)||"";a.style.display="none"}
function show(a){a.style.display="originalDisplay"in a&&a.originalDisplay?a.originalDisplay:""}function insertAfter(a,c){c.parentElement.insertBefore(a,c.nextSibling)}function toggleClass(a,c,e){(a.classList.contains(c)?!e:e)&&a.classList.toggle(c)}
var Videobox=function(a,c,e){c=void 0===c?{}:c;var k=this;this.isOpen=!1;this.animations=[];this.defaults={width:720,height:405,closeText:"Close",padding:30,root:document.body,animation:{duration:500,iterations:1,delay:0,easing:"ease-in-out"}};this.overlay=create("div","vbOverlay",function(){return k.close()});this.wrap=create("div","vbWrap");this.center=create("div","vbCenter");this.wrap.appendChild(this.center);this.responsive=create("div","vbResponsive");this.center.appendChild(this.responsive);
this.bottomContainer=create("div","vbBottomContainer");this.center.appendChild(this.bottomContainer);this.video=create("iframe","vbVideo");this.video.allowFullscreen=!0;this.video.frameBorder="0px";hide(this.video);this.responsive.appendChild(this.video);this.bottom=create("div","vbBottom");this.bottomContainer.appendChild(this.bottom);this.button=create("a","vbCloseLink",function(){return k.close()});this.button.innerHTML='<span id="vbCloseText">'+this.defaults.closeText+'</span><i class="vb-icon-close"></i>';
this.bottom.appendChild(this.button);this.caption=create("strong","vbCaption");this.bottom.appendChild(this.caption);this.closeText=this.button.querySelector("#vbCloseText");window.addEventListener("resize",function(){k.isOpen&&k.activeVideo&&k.setPlayerSizePosition()});a&&this.bind(a,c,e||this.linkMapper)};
Videobox.prototype.bind=function(a,c,e){c=void 0===c?{}:c;e=void 0===e?this.linkMapper:e;var k=this;"string"==typeof a&&(a=iterableToArray(document.querySelectorAll(a)));a.forEach(function(a){a.vbListener&&a.removeEventListener("click",a.vbListener);a.vbiListener&&a.removeEventListener("click",a.vbiListener);a.vbListener=function(p){p.preventDefault();p.stopPropagation();p=e(a);p.options=Object.assign({},c,p.options);k.open(p);return!1};a.addEventListener("click",a.vbListener)})};
Videobox.prototype.open=function(a){"VbInline"in window&&window.VbInline.close();this.close();a.options=Object.assign({},this.defaults,a.options);a.options.root||(a.options.root=document.body);this.setup(a);var c=a.origin.target,c=c.querySelector(c.getAttribute("data-target"))||c,e=this.wrap.getBoundingClientRect(),k=c.getBoundingClientRect();toggleClass(c,"vb_line_fix",!0);a.origin=Object.assign({},{x:k.left-e.left+c.clientWidth/2,y:k.top-e.top+c.clientHeight/2,width:c.clientWidth,height:c.clientHeight},
a.origin);toggleClass(c,"vb_line_fix",!1);this.changeVideo(a)};Videobox.prototype.close=function(){this.stop();[this.wrap,this.bottomContainer,this.overlay].forEach(function(a){return toggleClass(a,"visible",!1)});this.wrap.style.top="0px";this.wrap.style.left="0px";this.activeVideo=null;return!1};
Videobox.prototype.setPlayerSizePosition=function(){if(this.activeVideo){this.setPlayerPosition(this.activeVideo.options.root);var a=this.activeVideo.options.width,c=this.activeVideo.options.height;if(a+2*this.activeVideo.options.padding>this.wrap.clientWidth)var e=this.wrap.clientWidth-2*this.activeVideo.options.padding,c=c*e/a,a=e;c+2*this.activeVideo.options.padding>this.wrap.clientHeight&&(c=this.wrap.clientHeight-2*this.activeVideo.options.padding);a=100*c/a;this.responsive.style.paddingBottom=
a+"%";return a}};Videobox.prototype.linkMapper=function(a){var c=JSON.parse(a.getAttribute("data-videobox"))||{};if(c.root){var e=c.root;"string"==typeof e&&(e=iterableToArray(document.querySelectorAll(e)));0<e.length?c.root=e[0]:c.pop("root")}return{url:a.getAttribute("href")||"",title:a.getAttribute("title")||"",options:c,origin:{target:a}}};
Videobox.prototype.setup=function(a){this.closeText.innerText=a.options.closeText;a.options.root.appendChild(this.overlay);a.options.root.appendChild(this.wrap);this.setPlayerPosition(a.options.root)};
Videobox.prototype.setPlayerPosition=function(a){a=this.wrap.offsetParent;var c=this.wrap.getBoundingClientRect(),e=this.overlay.getBoundingClientRect(),k=this.wrap.offsetLeft-a.offsetLeft,p=e.left-c.left;this.wrap.style.top=this.wrap.offsetTop-a.offsetTop+(e.top-c.top)+"px";this.wrap.style.left=k+p+"px"};
Videobox.prototype.changeVideo=function(a){var c=this;this.activeVideo=a;this.caption.innerHTML=this.activeVideo.title;var e=this.setPlayerSizePosition();this.isOpen=!0;var k={top:this.activeVideo.origin.y-this.wrap.clientHeight/2+"px",left:this.activeVideo.origin.x-this.wrap.clientWidth/2+"px",maxWidth:this.activeVideo.origin.width+"px"};a={top:"0px",left:"0px",maxWidth:this.activeVideo.options.width+"px"};applyStyles(this.center,k);[this.wrap,this.overlay].forEach(function(a){return toggleClass(a,
"visible",!0)});toggleClass(this.wrap,"animating",!0);var p=100*this.activeVideo.origin.height/this.activeVideo.origin.width||e;p!=e&&this.animations.push(this.responsive.animate([{paddingBottom:p+"%"},{paddingBottom:e+"%"}],this.activeVideo.options.animation));e=this.center.animate([k,a],this.activeVideo.options.animation);e.onfinish=function(){return c.animateBotton()};this.animations.push(e);applyStyles(this.center,a);e.play()};
Videobox.prototype.animateBotton=function(){var a=this,c=this.bottomContainer.animate([{maxHeight:"0px"},{maxHeight:"200px"}],this.activeVideo.options.animation);toggleClass(this.bottomContainer,"visible",!0);c.onfinish=function(){return a.showVideo()};this.animations.push(c);c.play()};Videobox.prototype.showVideo=function(){this.isOpen&&!this.video.getAttribute("src")&&(show(this.video),this.video.setAttribute("src",this.activeVideo.url),toggleClass(this.wrap,"animating",!1))};
Videobox.prototype.stop=function(){this.animations.forEach(function(a){return a.cancel()});this.animations=[];this.isOpen=!1;this.video.setAttribute("src","");hide(this.video);toggleClass(this.wrap,"animating",!1)};var VideoboxObj=new Videobox;window.Videobox=VideoboxObj;
"undefined"!==typeof jQuery&&(jQuery.videobox=function(a){VideoboxObj.open(a)},jQuery.vbClose=function(){VideoboxObj.close()},jQuery.fn.videobox=function(a,c){a=void 0===a?{}:a;var e=iterableToArray(this);c?VideoboxObj.bind(e,a,c):VideoboxObj.bind(e,a)});function libBind(a){a.videobox=function(a){VideoboxObj.open(a)};a.vbClose=function(){VideoboxObj.close()};a.fn.videobox=function(a,e){a=void 0===a?{}:a;var c=iterableToArray(this);e?VideoboxObj.bind(c,a,e):VideoboxObj.bind(c,a)}}
"undefined"!=typeof jQuery&&libBind(jQuery);"undefined"!=typeof Zepto&&libBind(Zepto);
var VbInline=function(a,c,e){c=void 0===c?{}:c;var k=this;this.hidding=this.isOpen=!1;this.animations=[];this.hidden=[];this.defaults={width:720,height:405,closeText:"Close",padding:30,closeTimeout:1E3,animation:{duration:500,iterations:1,delay:0,easing:"ease-in-out"}};this.wrap=create("div","vbiWrap");this.responsive=create("div","vbiResponsive");this.wrap.appendChild(this.responsive);this.caption=document.createElement("span");this.caption.className="vb_video_title";this.wrap.appendChild(this.caption);
this.button=create("div","vbiClose",function(){return k.close()});this.button.innerHTML='<i class="vb-icon-circle-close-invert"></i>';this.wrap.appendChild(this.button);this.video=create("iframe","vbiVideo");this.video.allowFullscreen=!0;this.video.frameBorder="0px";hide(this.video);this.responsive.appendChild(this.video);a&&this.bind(a,c,e||this.linkMapper)};
VbInline.prototype.bind=function(a,c,e){c=void 0===c?{}:c;e=void 0===e?this.linkMapper:e;var k=this;"string"==typeof a&&(a=iterableToArray(document.querySelectorAll(a)));a.forEach(function(a){a.vbListener&&a.removeEventListener("click",a.vbListener);a.vbiListener&&a.removeEventListener("click",a.vbiListener);a.vbiListener=function(p){p.preventDefault();p.stopPropagation();p=e(a);p.options=Object.assign({},c,p.options);k.open(p);return!1};a.addEventListener("click",a.vbiListener)})};
VbInline.prototype.open=function(a){var c=this;"Videobox"in window&&window.Videobox.close();a.options=Object.assign({},this.defaults,a.options);var e=a.origin.target,e=e.querySelector(e.getAttribute("data-target"))||e;toggleClass(e,"vb_line_fix",!0);a.origin=Object.assign({},{x:e.clientWidth/2,y:e.clientHeight/2,width:e.clientWidth,height:e.clientHeight},a.origin);toggleClass(e,"vb_line_fix",!1);this.close(function(){return c.changeVideo(a)})};
VbInline.prototype.close=function(a){var c=this;this.stop();if(!this.hidding)if(this.wrap.parentElement&&this.activeVideo){this.hidding=!0;var e=this.activeVideo.origin.width+"px",k=this.wrap.animate([{maxWidth:this.activeVideo.options.width+2*this.activeVideo.options.padding+"px"},{maxWidth:e}],this.activeVideo.options.animation);k.onfinish=function(){return c.hide(a)};this.wrap.style.maxWidth=e;k.play();e=100*this.activeVideo.origin.height/this.activeVideo.origin.width+"%";k=this.responsive.animate([{paddingBottom:100*
this.activeVideo.options.height/this.activeVideo.options.width+"%"},{paddingBottom:e}],this.activeVideo.options.animation);this.responsive.style.paddingBottom=e;k.play()}else this.hide(a);return!1};VbInline.prototype.linkMapper=function(a){return{url:a.getAttribute("href")||"",title:a.getAttribute("title")||"",options:JSON.parse(a.getAttribute("data-videobox"))||{},origin:{target:a}}};
VbInline.prototype.setup=function(){insertAfter(this.wrap,this.activeVideo.origin.target);hide(this.activeVideo.origin.target);this.hidden.push(this.activeVideo.origin.target)};
VbInline.prototype.changeVideo=function(a){var c=this;this.activeVideo=a;this.setup();this.wrap.setAttribute("style",this.activeVideo.options.style);this.wrap.setAttribute("class",this.activeVideo.options.class);this.caption.innerHTML=this.activeVideo.title;toggleClass(this.button,"visible",!0);this.isOpen=!0;var e={maxWidth:this.activeVideo.options.width+2*this.activeVideo.options.padding+"px"},k=this.wrap.animate([{maxWidth:this.activeVideo.origin.width+"px"},e],this.activeVideo.options.animation);
applyStyles(this.wrap,e);k.onfinish=function(){return c.showVideo()};this.animations.push(k);k.play();e={paddingBottom:100*this.activeVideo.options.height/this.activeVideo.options.width+"%"};k=this.responsive.animate([{paddingBottom:100*this.activeVideo.origin.height/this.activeVideo.origin.width+"%"},e],this.activeVideo.options.animation);k.onfinish=function(){c.timer=window.setTimeout(function(){return toggleClass(c.button,"visible",!1)},a.options.closeTimeout)};this.animations.push(k);k.play();
applyStyles(this.responsive,e)};VbInline.prototype.showVideo=function(){this.isOpen&&(show(this.video),this.video.setAttribute("src",this.activeVideo.url))};VbInline.prototype.hide=function(a){this.wrap.parentElement&&this.wrap.parentNode.removeChild(this.wrap);this.hidden.forEach(function(a){return show(a)});this.hidden=[];this.hidding=!1;this.activeVideo=null;"function"==typeof a&&a()};
VbInline.prototype.stop=function(){this.animations.forEach(function(a){return a.cancel()});this.animations=[];this.isOpen=!1;this.video.setAttribute("src","");window.clearTimeout(this.timer);hide(this.video)};var VbInlineObj=new VbInline;window.VbInline=VbInlineObj;function libBind$1(a){a.vbInline=function(a){VbInlineObj.open(a)};a.vbiClose=function(a){VbInlineObj.close(a)};a.fn.vbInline=function(a,e){a=void 0===a?{}:a;var c=iterableToArray(this);e?VbInlineObj.bind(c,a,e):VbInlineObj.bind(c,a)}}
"undefined"!=typeof jQuery&&libBind$1(jQuery);"undefined"!=typeof Zepto&&libBind$1(Zepto);function detach(a){a.forEach(function(a){"querySelector"in a&&a.querySelector("#vbiWrap")&&VbInlineObj.close();a.parentNode.removeChild(a)})}
var VbSlider=function(a,c){c=void 0===c?{}:c;var e=this;this.queue=[];this.timeout=-1;this.moving=!1;this.visible=-1;this.detachedElements=[];this.options={moveAll:!1,target:"",singleDuration:500,doubleClickTimeout:200,animation:{duration:500,iterations:1,delay:0,easing:"ease-in-out"}};this.outer=createClass("div","vb_slider_outer");this.prev=createClass("div","vb_slider_prev",function(){return e.showPrev()});this.prev.innerHTML='<i class="vb-icon-prev"></i>';this.outer.appendChild(this.prev);this.wrap=
createClass("div","vb_slider_wrap");this.outer.appendChild(this.wrap);this.next=createClass("div","vb_slider_next",function(){return e.showNext()});this.next.innerHTML='<i class="vb-icon-next"></i>';this.outer.appendChild(this.next);this.content=createClass("div","vb_slider_cont");this.wrap.appendChild(this.content);this.buttons=iterableToArray(this.outer.querySelectorAll("i"));this.target=a;var k=this.attached;insertAfter(this.outer,this.target);this.content.appendChild(this.target);this.basis=parseInt(a.getAttribute("data-width"))||
k[0].clientWidth;this.options=Object.assign(this.options,c);toggleClass(this.content,"vb-slider__move-all",this.options.moveAll);this.setCount()};VbSlider.bind=function(a,c){c=void 0===c?{}:c;var e=this;"string"==typeof a&&(a=iterableToArray(document.querySelectorAll(a)));var k=[];a.forEach(function(a){var p=(a.getAttribute("data-target")||"").trim(),h=(a.getAttribute("data-move")||"single").trim(),v={};p&&(v.target=p);h&&(v.moveAll="all"==h);k.push(e.getSlider(a,Object.assign({},c,v)))});return k};
VbSlider.getSlider=function(a,c){c=void 0===c?{}:c;var e=this.sliders.find(function(c){return c.target==a});return e?(e.options=Object.assign(e.options,c),e):new VbSlider(a,c)};VbSlider.updateSizes=function(){this.sliders.forEach(function(a){return a.setCount()})};VbSlider.prototype.showPrev=function(){this.queueMove("r")};VbSlider.prototype.showNext=function(){this.queueMove("l")};VbSlider.prototype.setBasis=function(a){a!=this.basis&&(this.basis=a,this.setCount())};
VbSlider.prototype.getTarget=function(){return this.target};VbSlider.prototype.queueMove=function(a){var c=this;0<this.queue.length&&this.queue[this.queue.length-1]!=a?this.queue.pop():this.queue.push(a);0<=this.timeout&&clearTimeout(this.timeout);this.timeout=setTimeout(function(){c.timeout=-1;!c.moving&&0<c.queue.length&&c.move()},this.options.doubleClickTimeout)};
VbSlider.prototype.move=function(){var a=this;this.moving=!0;for(var c=this.queue.pop(),e=this.target.clientHeight,k=1;0<this.queue.length;)k+=this.queue.pop()==c?1:-1;if(0==k)this.moving=!1;else{0>k&&(c="l"==c?"r":"l",k=0-k);var p=(this.options.moveAll?this.visible:1)*k,p=p%(this.visible+this.detachedElements.length);if("l"==c)for(k=0;k<p&&0<this.detachedElements.length;k++)this.target.appendChild(this.detachedElements.shift());else for(k=0;k<p&&0<this.detachedElements.length;k++)this.target.insertBefore(this.detachedElements.pop(),
this.target.firstChild);var k=this.attached,r="l"==c?k.slice(0,k.length-this.visible):k.slice(this.visible);detach(r);var k=this.target.clientHeight,h=100*p/this.visible;if("l"==c)for(var v=r.length-1;0<=v;v--)this.target.insertBefore(r[v],this.target.firstChild);else for(v=0;v<r.length;v++)this.target.appendChild(r[v]);p=this.options.singleDuration?Object.assign({},this.options.animation,{duration:this.options.singleDuration*p}):this.options.animation;v=100*h/(h+100);r={transform:"translate("+("l"==
c?0:-v)+"%)"};v={transform:"translate("+("l"==c?-v:0)+"%)"};this.content.style.width=h+100+"%";h=this.content.animate([r,v],p);h.addEventListener("finish",function(){a.content.style.width="100%";a.skip(c)});h=this.content.animate([{height:e+"px"},{height:k+"px"}],p);this.content.style.height=k+"px";var u=0;this.options.target&&(u=(this.target.querySelector(this.options.target)||{offsetHeight:0}).offsetHeight/2);this.buttons.forEach(function(a){return a.style.top=u?u+"px":""})}};
VbSlider.prototype.skip=function(a){var c=this,e=this.attached;"l"==a?(a=e.slice(0,e.length-this.visible),detach(a),a.forEach(function(a){return c.detachedElements.push(a)})):"r"==a&&(a=e.slice(this.visible),detach(a),a.forEach(function(a){return c.detachedElements.unshift(a)}));0<this.queue.length&&0>this.timeout?this.move():this.moving=!1};
VbSlider.prototype.setCount=function(){var a=this.visible,c=this.target.clientWidth,e=this.attached,e=this.basis+e[0].offsetWidth-e[0].clientWidth,k=Math.floor(c/this.basis);1>k?k=1:e/(c/(k+1))<2-e/(c/k)&&k++;k!=a&&(this.visible=k,this.setAttached());this.content.style.height=this.target.clientHeight+"px";var p=0;this.options.target&&(p=(this.target.querySelector(this.options.target)||{offsetHeight:0}).offsetHeight/2);this.buttons.forEach(function(a){return a.style.top=p?p+"px":""})};
VbSlider.prototype.setAttached=function(){var a=this.attached;if(a.length<this.visible)for(a=a.length;a<this.visible&&0<this.detachedElements.length;a++)this.target.appendChild(this.detachedElements.shift());else if(a.length>this.visible)for(var c=a.length-1;c>=this.visible;c--)this.detachedElements.unshift(a[c]),detach([a[c]])};
$jscomp.global.Object.defineProperties(VbSlider.prototype,{attached:{configurable:!0,enumerable:!0,get:function(){return iterableToArray(this.target.children)}},isMoving:{configurable:!0,enumerable:!0,get:function(){return this.moving}}});VbSlider.sliders=[];window.addEventListener("resize",function(){return VbSlider.updateSizes()});window.VbSlider=VbSlider;
function libBind$2(a){a.vbSlider=function(a,e){e=void 0===e?{}:e;"length"in a&&(a=a[0]);return VbSlider.getSlider(a,e)};a.fn.vbSlider=function(a){a=void 0===a?{}:a;var c=iterableToArray(this);return VbSlider.bind(c,a)}}"undefined"!=typeof jQuery&&libBind$2(jQuery);"undefined"!=typeof Zepto&&libBind$2(Zepto);
function bindAll(){VideoboxObj.bind("a[rel^='videobox']",{root:document.querySelector(".mdl-layout.mdl-js-layout")||document.body});VbInlineObj.bind("a[rel^='vbinline']");VbSlider.bind(".vb_slider")}"loading"!=document.readyState?bindAll():document.addEventListener("readystatechange",function ready(){"loading"!=document.readyState&&(document.removeEventListener("readystatechange",ready),bindAll())});
